@media (max-width: 425px) {
  .lcpweb {
    display: none;
  }
}
@media (min-width: 426px) {
  .lcpmob {
    display: none;
  }
}
